.multiSelectColumn .ant-select-selection-item {
	display: none !important;	
}

.ant-tag {
	margin: 0px !important;
	font-family: var(--md-sys-typescale-title-small-font-family-name);
	display: inline-flex;
	align-items: center;
	gap: 0.25rem;
	padding: 2px 0.5rem;
	border-radius: 0.5rem;
	color: var(--md-sys-color-outline-light);
	border: 1px solid var(--md-sys-color-outline-light);
	background-color: var(--md-sys-color-surface-light);
}

.multiSelectColumn {
	width: 250px;
	min-width: 200px;
	max-width: 300px;
}
