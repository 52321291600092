.modalEdicaoEmMassa {
  width: 50% !important;
  max-width: 1000px;
  height: auto;
  max-height: 85vh;
  overflow-y: auto;
  margin: 40px auto;

  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.operacoesMassa {
  padding: 10px 16px !important;
}

.modalNovaObrigacao {
  width: 100% !important;
  max-width: 600px;
  height: auto;
  max-height: 80vh;
  overflow-y: auto;
  margin: 40px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.barraAcoesContainer {
  display: flex;
  justify-content: end;
  gap: 15px;
  position: relative;
  top: 40px;
}


.drawerHeader {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 8px 20px !important;
  border-bottom: 1px solid #e0e0e0 !important;
  background-color: #f5f5f5 !important;

}

.titleSection {
  display: flex !important;
  align-items: center !important;
  gap: 8px !important;
}

.drawerTitle {
  font-family: 'Inter' sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

}


.iconButton {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 18px;
  line-height: 1;
  gap: 0 !important;
}

.botoesHeader {
  display: flex;
  gap: 0 !important;
}

.clearButton,
.closeButton {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  border: none;
  cursor: pointer;
  gap: 0 !important;
}

.drawerWrapper {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #fff;
  border-radius: 8px;
  .ant-drawer-content {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.drawerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #e0e0e0;
}

.drawerTitle {
  font-size: 16px;
  font-weight: 600;
}

.drawerActions {
  display: flex;
  gap: 10px;
}

.mascara {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.5); 
	z-index: 1000; 
	cursor: not-allowed; 
}

@media (max-width: 468px) {
	.mascara {
		background: rgba(0, 0, 0, 0.7); 
		cursor: default; 
		z-index: 1050;
	}
}

