.icone {
	font-size: 24px !important;
}

.titulo {
	margin-bottom: 0;
	font-weight: 500;
}

.data {
	color: #848484 !important;
}

.iconeFechar {
	font-size: 24px !important;
	cursor: pointer;
}
