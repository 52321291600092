.cartaoNovidades {
	height: 100%;
}

.colunaDescricao {
	min-height: 160px;
	width: 100%;
	overflow-wrap: break-word;
	word-wrap: break-word;
	white-space: pre-wrap;
}

.dataInserida {
	color: #72787e !important;
}
