.containerLogs {
	background-color: #fff !important;
	height: 100%;
}

.colunaUsuario {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.dropdownFiltro {
	padding: 8px;
}

.areaTexto {
	width: 300px;
	margin-bottom: 8px;
}

.acoesFiltro {
	display: flex;
	justify-content: space-between;
}

.botaoFiltro {
	width: 48%;
}

.containerLoading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50vh;
}

.animacaoLoading {
	width: 50%;
	height: 50%;
}

.iconeGrande{
	font-size: 20px;
}