.customHeader {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 44px;
	background: #ededed;
	border-bottom: 1px solid #bdbdbd;
	border-radius: 8px 8px 0 0;
	padding: 25px 16px;
}

.headerContent {
	display: flex;
	align-items: center;
	flex-grow: 1;
}

.headerRadio {
	margin-right: -10px !important;
}

.novaObrigacao {
	color: #4a4a4b !important;
}

.emProgresso {
	color: #004f50 !important;
}

.headerNewTask {
	font-family: 'Inter, sans-serif';
	font-size: 12px;
	font-weight: 400;
	line-height: 14.52px;
	text-align: left;
	color: #4a4a4b;
	padding: 4px 8px;
	border-radius: 4px;
	margin-right: 16px;
}

.headerEmpresa {
	font-family: 'Inter, sans-serif';
	font-size: 12px;
	font-weight: 400;
	line-height: 14.52px;
	text-align: left;
	color: #757575;
}

.headerSeparator {
	margin: 0 8px;
	color: #757575;
}

.headerIconGroup {
	display: flex;
	align-items: center;
	position: absolute;
	right: 50px;
}

.espacadorR {
	border-right: 1px solid;
	padding-right: 0.5rem;
    height: 28px !important;
}

.espacadorL {
	display: flex;
	align-items: center;
	border-left: 1px solid;
	padding-left: 0.5rem;
    height: 28px !important;
}

.dateButton{
    padding: .25rem .25rem .25rem .5rem !important;
    height: 24px;
    display: flex;
    align-items: center;
    border: none;
    width: 100%;
}