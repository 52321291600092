.containerRegistroAtividade {
	padding: 0 25px 20px 25px;
}

.containerHeaderAtividades {
	.iconeLog {
		font-size: 16px;
	}
}

.containerTextoAtividade {
	text-align: left;
}

.avatars {
	font-family: 'Inter', sans-serif;
	font-size: 14px !important;
	font-weight: 500;
	line-height: 14.52px;
	text-align: left;
}

.cargoSpan {
	color: rgb(121, 121, 121);
	font-weight: 500;
}

.ant-drawer-content-wrapper {
	top: 51px !important; 
}
