.ant-layout-sider-trigger {
	background: var(--md-ref-palette-primary40) !important;
    box-shadow: var(--md-sys-elevation-level-1);
}

.ant-menu-item-disabled .ant-menu-title-content {
	color: var(--md-ref-palette-neutral60) !important;
}
.ant-menu-item-disabled .ant-menu-item-icon {
	color: var(--md-ref-palette-neutral60) !important;
}

.ant-menu-item-disabled:hover {
	background-color: var(--md-ref-palette-neutral95) !important;
}

.cdIGpm {
	height: 40px;
	width: 40px;
	padding: 0px !important;
	border-radius: 2rem !important;
	padding-left: 0.5rem !important;
}

.ant-layout .ant-layout-sider {
	background-color: var(--md-sys-color-surface-light);
	height: 100vh;
	position: fixed;
    box-shadow: var(--md-sys-elevation-level-1);
}

.ant-menu-dark {
	background-color: var(--md-sys-color-surface-light);
}

.logoIso {
	position: absolute;
	bottom: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 16px;
	margin-bottom: 40px;
	height: 84;
	align-items: center;
	background-color: var(--md-sys-color-surface-light);
}

.siteLayout {
	display: 'flex';
	justify-content: space-between;
	padding: 0 16px;
	transition: width 0.2s;
	margin-left: 220px;
}

.botaoPerfil{
    cursor: pointer;
}

.cabecalhoMenu {
    display: flex;
    justify-content: space-between; 
    padding: 0 1.6rem;
    transition: width 0.2s;
    margin: .75rem;
    gap: .5rem;
}

.iconsRight {
    display: flex;
    gap: 1rem;
}
