.modalConcluirObrigacaoForm {
	background: none !important;
	max-width: 400px !important;
	padding: 0px !important;
	min-width: 400px !important;
}

.modalTitle {
	font-family: 'Inter', sans-serif !important;
	font-size: 14px !important;
	font-weight: 700 !important;
	line-height: 16.8px !important;
	text-align: left !important;
	color: #000000 !important;
	margin-bottom: 20px !important;
}

.dataConclusaoInput {
	max-width: 150px !important;
	margin-bottom: 16px !important;
}

.largeInput {
	width: 100% !important;
	margin-bottom: 16px !important;
}

.radioGroupContainer {
	display: flex !important;
	justify-content: space-between !important;
	align-items: center !important;
	margin-bottom: 20px !important;
}

.radioLabel {
	font-size: 14px !important;
	color: #555 !important;
}

.buttonsContainer {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	gap: 16px !important;
	margin-top: 20px !important;
}

input[disabled],
textarea[disabled] {
	opacity: 1; /* Mantém a visibilidade total */
	color: #000000 !important; /* Ajuste a cor do texto, se necessário */
	background-color: #ffffff !important; /* Opcional: define uma cor de fundo clara */
	cursor: not-allowed; /* Mantém o cursor indicando que o campo está desabilitado */
}
