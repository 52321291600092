.IconeCalendarioMensal {
	font-size: 1.375rem !important;
}

.periodoSelecionado {
	width: 30%;
}

.periodoSelecionadoTexto {
	font-family: 'Inter', sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	text-align: left;
}

.filtros {
	color: rgb(160, 160, 160) !important;
}

.filtrosStatus {
	font-style: italic;
	color: rgb(160, 160, 160);
	margin-left: 8px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.modalFormContainer {
	width: 100% !important;
	border-radius: 8px !important;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
}