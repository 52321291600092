.calendarioCustomizado {
	justify-content: space-around;
}

.cabecalhoCalendario {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #e0e0e0 !important;
}

.iconeCabecalhoCalendario {
	color: #3c3c3c !important;
}

.tituloCabecalhoCalendario {
	font-family: var(--md-sys-typescale-label-medium-font-family-name);
	font-weight: 500;
	color: #3c3c3c !important;
	text-transform: capitalize;
	flex-grow: 1;
	text-align: center;
	width: 28px !important;
	height: 28px !important;
}

.css-flbe84-MuiDayCalendar-weekContainer {
	justify-content: space-between;
}

.diaPadrao,
.diaConcluidaNoPrazo,
.diaConcluidaComAtraso,
.diaVencidaNaoConcluida,
.diaNaoConcluidaNoPrazo {
	font-family: var(--md-sys-typescale-label-medium-font-family-name) !important;
	font-size: 14px !important;
	font-weight: 600 !important;
	line-height: 1.5 !important;
	color: #3c3c3c !important;
	text-align: center;
	border-radius: 8px !important;
	padding: 8px 10px !important;
	width: 28px !important;
	height: 28px !important;
}

.diaConcluidaNoPrazo {
	background-color: #3ea26e !important;
	color: #ffffff !important;
}

.diaConcluidaComAtraso {
	background-color: #f57c00 !important;
	color: #ffdad6 !important;
}

.diaVencidaNaoConcluida {
	background-color: #ffdad6 !important;
	color: #d32f2f !important;
}

.diaNaoConcluidaNoPrazo {
	background-color: #186584 !important;
	color: #ffffff !important;
}

.botaoDiaSelecionado {
	background-color: #007bff !important;
	color: #ffffff !important;
}

.botaoDiaSelecionado:hover {
	background-color: #0056b3 !important;
	color: #ffffff !important;
}

.botaoDiaSelecionado:active {
	background-color: #004085 !important;
	color: #ffffff !important;
}

.botaoLimparSelecao {
	display: flex;
	position: relative;
	top: 0px;
	margin: auto;
	margin-bottom: 80px;
	padding: 8px 16px;
	background-color: #d9e1e6;
	color: #007bff;
	border: none;
	border-radius: 4px;
	text-align: center;
	text-decoration: none;
	font-size: 14px;
	cursor: pointer;
	transition:
		background-color 0.3s,
		color 0.3s;
}

.botaoLimparSelecao:hover {
	background-color: #e2e6ea;
	color: #0056b3;
}

.botaoLimparSelecao:active {
	background-color: #dae0e5;
	color: #004085;
}

.botaoLimparSelecao:focus {
	outline: none;
	box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

.calendarioCustomizado .MuiPickersCalendar-week {
	gap: 6px;
}

.MuiPickersCalendar-week {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@media (min-width: 1024px) {
	.calendarioCustomizado {
		height: auto;
		margin: 0 auto;
	}

	.tituloCabecalhoCalendario {
		font-size: 1.2rem;
	}

	.diaPadrao,
	.diaConcluidaNoPrazo,
	.diaConcluidaComAtraso,
	.diaVencidaNaoConcluida,
	.diaNaoConcluidaNoPrazo {
		font-family: var(--md-sys-typescale-body-medium-font-family-name) !important;
		font-size: var(--md-sys-typescale-label-large-font-size) !important;
		padding: 0.75rem !important;
	}

	.botaoLimparSelecao {
		margin-bottom: 60px;
		padding: 10px 20px;
		font-size: 16px;
	}
}
