.cartaoTotalObrigacoes {
	width: 100%;
	display: flex;
	position: relative;
	background: #c1e8ff !important;
	padding: 12px 16px;
	height: auto;
	margin: 20px 0 140px 0;
}

.containerTotalObrigacoes,
.obrigacoesEncontradas {
	width: 100%;
}
