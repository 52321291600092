.modalConfirmacao {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 516px;
}

.header {
  display: flex;
  align-items: center;
  gap: 8px;

  h2 {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #181c20;
    margin: 0;
  }

  .iconAttention {
    font-size: 20px;
    color: #ffffff;
    background-color: #ffab00;
    border-radius: 50%;
    padding: 0px;
  }

}

.modalContent {
  p {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #181c20;
    margin: 0;
  }

  .checkboxContainer {
    margin-top: 16px;
    display: flex;
    align-items: center;

    label {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #181c20;
      margin-left: 8px;
    }
  }
}

.footer {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 16px;

  .error {
    width: 140px;
    height: 36px;
    border: 1px solid #ffd9d4;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #ff4d4f;
    text-align: center;
    background: none;
    cursor: pointer;

    &:hover {
      background: #ffe8e6;
    }
  }

  .default {
    width: 166px;
    height: 36px;
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #041f1f;
    background: #cce8e7;
    text-align: center;
    cursor: pointer;

    &:hover {
      background: #b3dcd9;
    }
  }
}