.filtros-obrigacoes-container {
	position: relative;
}

.filtros-wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 15px;
	width: 100%;
	flex-wrap: nowrap;
}

.button-container {
	display: flex;
	align-items: center;
	padding-bottom: 15px;
}

.tags-container {
	display: flex;
	align-items: center;
	gap: 10px;
	flex: 1;
	overflow-x: auto;
	padding-bottom: 10px;
	scrollbar-width: thin;
	-ms-overflow-style: none;
}

/* Adicionando um container para alinhar o botão "Limpar Filtros" à direita */
.tags-wrapper {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
}

.tags-content {
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
}

/* Estilo para o botão "Limpar Filtros" */
.limpar-filtros-button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 16px;
	white-space: nowrap;
	margin-left: auto;
	/* Alinha o botão à direita */
	margin-bottom: 15px !important;
}

.tags-container::-webkit-scrollbar {
	height: 8px;
}

.tags-container::-webkit-scrollbar-thumb {
	background-color: #888;
	border-radius: 10px;
}

.tags-container::-webkit-scrollbar-thumb:hover {
	background-color: #555;
}

.tags-container::-webkit-scrollbar-track {
	background: transparent;
}

.filtros-button {
	margin-right: 20px;
}

.custom-tag {
	height: 30px;
}

.dropdown-container {
	position: absolute;
	transition: transform 0.2s ease-in-out;
}

.modal-position-top-left {
	position: absolute !important;
	top: var(--top-position) !important;
	left: var(--left-position) !important;
	transform: translate(0, 0) !important;
	transition: transform 0.2s ease-in-out !important;
}

.conteudoGraficoContainer {
	height: 100% !important;
	border-radius: 8px 0px 0px 8px !important;
	background: #ffff !important;
}

.campoFiltro{
	width: 100%;
	gap: .25rem;
}

.filtroContainer{
	margin-top: 1rem;
}

.acoesFiltro{
	margin: 2rem 0rem;
	width: 100%;
}

.aplicarFiltrosButton {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 16px;
	white-space: nowrap;
	margin-left: auto;
	margin-bottom: 15px !important;
}

.estiloData{
	align-items: flex-end;
}