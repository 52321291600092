.deleteIcon {
	font-size: 24px !important;
	cursor: pointer !important;
	color: #ba1a1a !important;
	position: relative !important;
	left: 10px !important;
}

.required,
.alertaCaractere {
	color: red !important;
}

.textArea {
	height: 70px;
}

.loadingContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50vh;
}

.lottie {
	width: 50%;
	height: 50%;
}

.emptyContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50vh;
}

.dateLabel {
	color: #72787e;
}

.containerDescricao {
	width: 100%;
	overflow-wrap: break-word;
	word-wrap: break-word;
	white-space: pre-wrap;
}
