.envioEmailsContainer {
	padding: 16px;
	border: 1px solid #bdbdbd;
	border-radius: 8px;
	background: #ffffff;
}

.typographyH6 {
	font-family: 'Inter', sans-serif !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	line-height: 14.25px !important;
	margin-bottom: 18px !important;
}

.typographyBody1 {
	font-size: 13px !important;
	font-weight: 400;
	line-height: 14.52px;
	text-align: left;
	margin-bottom: 4px;
}

.captionText {
	font-family: 'Inter', sans-serif !important;
	font-size: 10px !important;
	font-weight: 400 !important;
	text-align: left !important;
	color: #000000 !important;
}

.selectField {
	width: 100%;
	height: 40px;
	padding: 10px;
	border-radius: 4px;
	background: #f3f3f3 !important;
	border: none !important;
	box-sizing: border-box;
	color: #878686;
	margin-bottom: 20px;

	&::placeholder {
		font-family: 'Inter', sans-serif;
		font-size: 12px;
		font-weight: 400;
		line-height: 14.52px;
		color: #878686;
	}
}

.avatarGroupContainer {
	display: flex;
	align-items: center;
}

.iconGrupos {
	width: 18px;
	margin-right: 8px;
}

.icon {
	margin-right: 8px;
	color: #000000;
}

.captionText {
	font-family: 'Inter', sans-serif;
	font-size: 10px;
	font-weight: 400;
	color: #878686;
	margin-top: 16px;
}

@media (max-width: 768px) {
	.avatarGroupContainer {
		flex-direction: column !important;
		align-items: flex-start !important;
		margin-top: 8px !important;
	}

	.avatarGroupContainer .icon {
		margin-right: 0 !important;
		margin-bottom: 4px !important;
	}

	.selectField {
		margin-bottom: 16px !important;
	}

	.captionContainer {
		margin-top: 16px !important;
	}
}

.espacamentoTop {
	margin-top: 8px;
}
