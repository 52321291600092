.calculoPeriodicidadeContainer {
	padding: 16px;
	border: 1px solid #bdbdbd;
	border-radius: 8px;
}

.typographyH6 {
	font-family: 'Inter', sans-serif !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	line-height: 14.25px !important;
	margin-bottom: 18px !important;
}

.typographyBody1 {
	font-family: 'Inter', sans-serif;
	font-size: 13px !important;
	font-weight: 400;
	line-height: 14.52px;
	text-align: left;
	margin-bottom: 4px;
}

.espacamentoTop {
	margin-top: 8px;
}
