.avatar {
	user-select: none;
	height: 40px;
	width: 40px;

	min-width: 40px;
	min-height: 40px;

	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
}
