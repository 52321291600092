.containerLabels {
	gap: 0.25rem;
}

.dataCartaoAtrasado {
	color: #ba1a1a !important;
}

.iconeWarning {
	font-size: 0.75em;
	color: #fab35a !important;
}

.iconeAprovado {
	font-size: 0.9em;
	color: #3ea26e !important;
}

.iconeReprovado {
	font-size: 0.75em;
	color: #ba1a1a !important;
}
