.botaoConfirmarCadastroUsuario {
	width: 100% !important;
}

.botoesCadastrarUsuarios {
	width: 100% !important;
}

.validacaoSenha {
	list-style-type: none;
	padding: 0;

	li {
		margin-top: 10px;
	}
}

.numerosStepComponente {
	font-size: 12px;
}
