:global {
  .ant-drawer-content-wrapper {
    height: 100% !important;
    border-radius: 88px !important;
  }

  .ant-drawer-mask {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    background-color: rgba(0, 0, 0, 0.5) !important;
    z-index: 1000; 
  }
}

.modalEdicaoEmMassa {
  padding: 0 20px;
  background-color: #fff;
  border-radius: 88px !important;
  padding-bottom: 50px;
}

form {
  h4 {
    margin: 20px 0;
  }

  .buttonsContainer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: sticky; 
  }
}

.switchEhLegal {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
}

.ehLegal {
  margin-bottom: 20px !important;
}

.campo-com-aviso {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  label {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #000000;
    margin-bottom: 5px;
  }

  .mensagem-erro,
  .mensagem-aviso {
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 11.2px;
    text-align: left;
    color: #ff4d4f;
    margin-top: 4px;
    margin-left: 8px !important;

    .icon {
      margin-left: 4px !important;
    }
  }

  .mensagem-aviso {
    color: #d32f2f;
    margin-right: 4px !important;

    .icon {
      margin-right: 4px !important;
    }
  }
}

.configuracoes-envio {
  margin-top: 20px;

  .ant-select {
    width: 100%;
  }

  h4 {
    margin-bottom: 12px;
    font-weight: bold;
  }
}

.labelComFechar {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.iconeFechar {
  display: flex;
  position: relative;
  cursor: pointer;
  margin-left: 8px;
  font-size: 16px !important;
}

@media (min-width: 768px) {
  :global {
    .ant-drawer-content-wrapper {
      height: 100% !important;
      border-radius: 50px !important;
    }
  }

  .modalEdicaoEmMassa {
    padding-bottom: 40px !important;
  }

  form {
    h4 {
      margin: 16px 0;
    }

    .buttonsContainer {
      margin-top: 30px;
      gap: 15px;
    }
  }

  .switchEhLegal {
    flex-direction: row;
    justify-content: space-between;
  }

  .campo-com-aviso {
    margin-bottom: 20px;

    label {
      font-size: 14px;
    }

    .mensagem-erro,
    .mensagem-aviso {
      font-size: 12px;
    }
  }

  .configuracoes-envio {
    margin-top: 30px;

    .ant-select {
      width: 80%;
    }
  }
}

@media (min-width: 1000px) {
  :global {
    .ant-drawer-content-wrapper {
      height: 100% !important;
      border-radius: 30px !important;
    }
  }

  .modalEdicaoEmMassa {
    padding-bottom: 60px !important;
  }

  form {
    h4 {
      margin: 24px 0;
    }

    .buttonsContainer {
      gap: 20px;
      margin-bottom: 20px;
    }
  }

  .campo-com-aviso {
    margin-bottom: 24px;

    label {
      font-size: 16px;
    }

    .mensagem-erro,
    .mensagem-aviso {
      font-size: 14px;
    }
  }

  .configuracoes-envio {
    margin-top: 40px;

    .ant-select {
      width: 70%;
    }
  }
}

