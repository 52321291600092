.obrigacoesPagina {
	display: flex;
	justify-content: space-between;
	overflow: hidden;
}

.obrigacaoCol {
	width: 100rem;
}

.conteudoGraficoContainer {
	height: 100% !important;
	border-radius: 8px 0px 0px 8px !important;
	background: #ffff !important;
	overflow: auto;
}

.flexContainer {
	flex-direction: column;
}

.flexGraficoContainer {
	flex-direction: column;
	justify-content: start !important;
	align-items: end;
	gap: 60px;
}

.calendarHeader {
	text-align: center;
	color: white;
}

.calendarHeaderTitle {
	font-weight: bold;
	text-transform: uppercase;
}

:global {
	.custom-select-container {
		position: relative !important;
		right: 0 !important;
		bottom: 4.2rem !important;
		z-index: 1000 !important;
	}
}

.btnFiltro {
	padding: 8px 4px !important;
	border-radius: 0px 0px 8px 8px !important;
	background-color: var(--md-sys-color-tertiary-light) !important;
	border-color: var(--md-sys-color-tertiary-light) !important;
	box-shadow:
		0px 1px 2px 0px rgba(0, 0, 0, 0.3),
		0px 1px 3px 1px rgba(0, 0, 0, 0.15);
	&:hover {
		background-color: var(--md-sys-color-tertiary-light);
		box-shadow: none;
	}
}

.btnCalendario {
	padding: 8px 4px !important;
	border-radius: 0px 0px 8px 8px !important;
	box-shadow:
		0px 1px 2px 0px rgba(0, 0, 0, 0.3),
		0px 1px 3px 1px rgba(0, 0, 0, 0.15);
	&:hover {
		box-shadow: none;
	}
}

.btnFecharSider {
	padding: 8px 4px !important;
	border-radius: 0px 0px 8px 8px !important;
	background-color: var(--md-sys-color-error-light) !important;
	border-color: var(--md-sys-color-error-light) !important;
	box-shadow:
		0px 1px 2px 0px rgba(0, 0, 0, 0.3),
		0px 1px 3px 1px rgba(0, 0, 0, 0.15);
	&:hover {
		background-color: var(--md-sys-color-error-light);
		box-shadow: none;
	}
}

.btnOpenFiltro {
	width: 40px !important;
	padding: 16px 4px !important;
	border-radius: 8px 0px 0px 8px !important;
	background-color: var(--md-sys-color-tertiary-light) !important;
	border-color: var(--md-sys-color-tertiary-light) !important;
	&:hover {
		background-color: var(--md-sys-color-tertiary-light);
		box-shadow: none;
	}
}
.btnOpenCalendario {
	width: 40px !important;
	padding: 16px 4px !important;
	border-radius: 8px 0px 0px 8px !important;
	&:hover {
		box-shadow: none;
	}
}
.botoesContainer {
	background-color: #ffff;
}

.estiloData {
	align-items: flex-end;
	gap: 0.25rem;
	align-items: center;
}

.campoFiltro {
	width: 100%;
}

.campoPeriodo {
	margin-top: 0.5rem;
	gap: 0.25rem;
}

.btFiltrosAplicados {
	margin-bottom: 0.5rem;
}

.espacamentoContainer {
	gap: 0.5rem;
}

.fontSizeLarge {
	font-size: 20px;
}

.menuFlutuante {
	position: fixed;
	right: 0px;
	top: calc(50% - 130px);
	gap: 0.5rem;
}

.btFlutuante {
	writing-mode: vertical-rl;
	transform: rotate(180deg);
	gap: 0.5rem;
}

.containerObrigacoes {
	min-height: 100vh;
}

.obrigacoesSider {
	position: fixed;
	right: 0;
	top: 0;
	height: calc(100vh - 65px);
	z-index: 10;
	margin-top: 65px;
}

.loadingContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: '50vh';
}

.tamanhoLoading {
	width: 50%;
	height: 50%;
}

.iconeAviso {
	font-size: 14px;
	color: #fab35a;
}

.avisoSemObrigacao {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 24vh;
}
