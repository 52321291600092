.codigoObrigacao {
    font-size: 1em !important;
    color: #333 !important;
    font-weight: bold !important;
    margin-bottom: 5px !important;
}

.nomeObrigacao, 
.nomeObrigacaoSub {
    font-size: 1.1em !important;
    color: #555 !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100% !important;
}



.nomeObrigacaoSub {
    font-size: 1em !important;
    color: #333 !important;
    margin-left: 10px !important;
}

.codigoObrigacaoSub {
    font-size: 0.9em !important;
    color: #333 !important;
    font-weight: bold !important;
    margin-bottom: 4px !important;
    margin-right: 15px !important;
}

.customConteudoClass {
    padding: 10px !important;
    background-color: #f0f0f0 !important;
}

.cartaoObrigacoes {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    padding: 20px !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    border-radius: 8px !important;
    overflow: hidden !important;
}

.conteudoWrapper {
    display: flex;
    position: relative;
    right: 10px;
    max-width: 100%;
    overflow: hidden;
    margin-right: 50px;
}

.conteudoWrapper [title]:hover::after {
    content: attr(title);
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    white-space: nowrap;
    z-index: 1000;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    margin-top: -10px;
}
