.acoesContainer {
	padding: 16px;
	border: 1px solid #e0e0e0;
	border-radius: 8px;
}

.typographyH6 {
	font-family: 'Inter', sans-serif !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	line-height: 14.25px !important;
	margin-bottom: 18px !important;
}

.concluir {
	background-color: #cce8e7 !important;
	padding: 0.5rem 0.75rem 0.5rem 0.5rem !important;
}

.alterarVencimento {
	background-color: #ffddb0 !important;
	border: none !important;
	padding: 0.5rem 0.75rem 0.5rem 0.5rem !important;
}

.cancelar {
	background-color: #ffdad6 !important;
	padding: 0.5rem 0.75rem 0.5rem 0.5rem !important;
}
.anexos {
	padding: 0.5rem 0.75rem 0.5rem 0.5rem !important;
}
.cancelar,
.concluir,
.alterarVencimento,
.anexos {
	color: #000 !important;
	font-family: 'Inter', sans-serif !important;
	font-size: 12px !important;
	font-weight: 500 !important;
	line-height: 16px !important;
	text-align: left !important;
	text-transform: none !important;
}

.espacamentoTop {
	margin-top: 2px !important;
}
