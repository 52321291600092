.criacaoObrigacaoContainer {
	padding: 16px;
	border: 1px solid #bdbdbd;
	border-radius: 8px;
	min-width: 400px;
}

.rowContainer {
	display: flex;
	justify-content: space-between;
	align-items: start;
}

.avatarGroupContainer {
	display: flex !important;
	align-items: center !important;
	height: 50px;
}

.avatarGroup {
	display: flex !important;
	align-items: flex-end !important;
	gap: 10px;
}

.icon {
	margin-right: 8px !important;
	color: #000 !important;
}

.iconOrigem {
	font-size: 20px;
}

.avatars {
	margin-left: 8px;
}

.typographyH6 {
	font-family: 'Inter', sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 19.36px;
}

.typographyBody1 {
	font-family: 'Inter', sans-serif;
	font-size: 13px !important;
	font-weight: 400;
	line-height: 14.52px;
	text-align: left;
	margin-bottom: 4px;
}

.requiredAsterisk {
	color: red;
	margin-left: 4px;
}

.textAreaFormulario {
	width: 100%;
	padding: 10px;
	border-radius: 5px;
	font-family: 'Inter', sans-serif;
	font-size: 14px;
	resize: vertical;
	color: #333;
	outline: none;
	resize: none !important;

	font-size: 14px !important;
	font-weight: 400 !important;
	line-height: 14.52px !important;
	text-align: left !important;

	&::placeholder {
		font-family: 'Inter', sans-serif;
		font-size: 15px !important;
		font-weight: 400;
		line-height: 14.52px;
		text-align: left;
		margin-bottom: 4px;
	}
}

.ant-select-dropdown-hidden {
	z-index: 999999 !important;
}

.addButton {
	width: 38px;
	height: 38px;
}

.menuItem {
	border-radius: 0.5rem !important;
	padding: 8px 16px !important;
	display: flex !important;
	flex-direction: column !important;
	align-items: flex-start !important;
}

.labelMenu {
	margin-bottom: 8px;
}

.selectAnd {
	width: 300px;
}

.checkIcon {
	color: #265e54;
}


.dropdownFooter {
    padding: 8px;
    text-align: right; 
}
