.avatarGroupContainer {
	display: flex !important;
	align-items: center !important;
	gap: 0.5rem !important;
}

.avatarGroup {
	display: flex !important;
}

.avatarBox {
	position: relative !important;
	margin-right: 8px !important;
}

.avatarCustom {
	width: 32px !important;
	height: 32px !important;
}

.avatars {
	margin-left: 8px !important;
	font-family: 'Inter', sans-serif !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	line-height: 14.52px !important;
	text-align: left !important;
}

.required {
	color: red !important;
}

.removeButton {
	position: absolute !important;
	top: -8px !important;
	right: -8px !important;
	background-color: #fff !important;
	border: 1px solid #BDBDBD !important;
	border-radius: 50% !important;
	padding: 2px !important;

	&:hover {
		background-color: #f0f0f0 !important;
	}

	& svg {
		font-size: 16px !important;
	}
}

.popoverContent {
	padding: 3px;

	.memberItem {
		margin: 3px;
		display: flex !important;
		align-items: center !important;
		padding: 5px;
		background-color: rgb(255, 255, 255);
		border-radius: 5px;
		transition: background-color 0.3s ease;

		&:hover {
			background-color: rgb(219, 215, 215);
			cursor: pointer;

		}

		span {
			flex-grow: 1;
			display: flex;
			align-items: center;
		}

		.closeButton {
			margin-left: auto !important;
			padding: 4px;
		}
	}
}




.avatarCustom {
	width: 32px !important;
	height: 32px !important;
}

.clickableAvatar {
	cursor: pointer !important;
	background-color: #80D4D5 !important;
	color: #2F2E2E !important;
}

.clickableAvatar:hover {
	opacity: 0.9 !important;
}

.bgAvatar {
    background-color: var(--bg-color, #80D4D5) !important;
    color: var(--text-color, #2F2E2E) !important;
}

