.pieChartContainer {
	margin-top: 20px;
}

.recharts-default-legend {
	display: flex !important;
	flex-direction: column !important;
	gap: 8px;
	margin-top: 10px;
	margin-bottom: -20px;
	font-family: 'Inter', sans-serif;
}

.legendaGraficoTorta {
	color: #596579 !important;
	font-size: 14px;
	font-weight: 600;
}