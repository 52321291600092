.cartaoObrigacoes {
	background-color: #fff !important;
	padding: 1.5em !important;
	margin-bottom: 0.5rem;
	border-radius: 12px !important;
	background-color: #f9f9f9;
}

.subCartaoObrigacoes {
	margin-top: 0.5rem;
	padding: 1.5em !important;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.subCartaoObrigacoes .row {
	margin-bottom: 10px;
}

.subCartaoObrigacoes .cabecalho,
.subCartaoObrigacoes .conteudo,
.subCartaoObrigacoes .responsabilidades,
.subCartaoObrigacoes .acoes {
	margin-bottom: 15px;
}

.subCartaoObrigacoes .acoes {
	display: flex;
	justify-content: flex-end;
}

.subCartaoObrigacoes h3 {
	font-size: 1.2em;
	color: #333;
}

.subCartaoObrigacoes p {
	font-size: 1em;
	color: #666;
}