.listaAnexosContainer {
	width: 100% !important;
	border: 1px solid #c2c7ce !important;
	border-radius: 8px !important;
	padding: 16px !important;

	.headerRow {
		display: flex;
		align-items: center !important;
		text-align: left !important;
		padding-bottom: 16px !important;
		margin-bottom: 16px !important;
		border-bottom: 1px solid #d3d3d3 !important;
		color: #333 !important;
	}

	.anexoRow {
		display: flex;
		align-items: center !important;
		text-align: left !important;
		border-bottom: 1px solid #e0e0e0 !important;
		margin-bottom: 10px;
		padding-bottom: 10px;

		&:last-child {
			border-bottom: none !important;
		}

		&:hover {
			background-color: #f0f0f0 !important;
		}

		.descricaoWrapper {
			display: flex !important;
			align-items: center !important;
			background-color: #f5f5f5 !important;
			border: 1px solid #c2c7ce !important;
			border-radius: 4px !important;
			width: 100% !important;
		}

		.descricaoInput {
			flex-grow: 1 !important;
			text-align: left !important;
			font-size: 0.875rem !important;

			resize: vertical !important;
			background-color: transparent !important;
			border: none !important;
		}

		.descricaoTexto {
			flex-grow: 1 !important;
			text-align: left !important;
			font-size: 0.875rem !important;
			white-space: nowrap !important;
			overflow: hidden !important;
			text-overflow: ellipsis !important;
			color: #333 !important;

			cursor: pointer !important;
			transition:
				color 0.3s,
				text-decoration 0.3s !important;
		}

    .descricaoCell {
      padding: 0.5rem;
      margin-top: 0.25rem;
      position: relative;
  
      &:hover {
          background-color: var(--md-ref-palette-neutral90);
          border-radius: 0.5rem;
          
          .descricaoTexto {
              max-width: calc(100% - 24px); // Ajusta o tamanho do texto para dar espaço ao ícone
          }
  
          .hoverIcon {
              opacity: 1;
              pointer-events: auto;
          }
      }
  
      .descricaoTextoWrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
      }
  
      .descricaoTexto {
          flex: 1;
          transition: max-width 0.3s;
      }
  
      .hoverIcon {
          opacity: 0;
          transition: opacity 0.3s;
          pointer-events: none;
      }
  }
  
		.typographyClickable {
			cursor: pointer !important;
			transition: color 0.3s !important;

			&:hover {
				color: #007bff !important;
				text-decoration: underline !important;
			}
		}

		.descricaoWrapper {
			display: flex;
			align-items: center;
		}

		.descricaoBotaoCheck,
		.descricaoBotaoNoCheck {
			padding: 2px !important;
			color: #ffffff !important;
			border-radius: 4px !important;
			margin-left: 4px !important;
			min-width: 22px !important;
			font-size: 1rem !important;

			&.descricaoBotaoCheck {
				background-color: #00796b !important;

				&:hover {
					background-color: #004d40 !important;
				}
			}

			&.descricaoBotaoNoCheck {
				background-color: #d32f2f !important;

				&:hover {
					background-color: #9a0007 !important;
				}
			}
		}
	}

	.noAnexosText {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: left !important;
		color: #888888 !important;
		padding: 24px 0 !important;
	}
}

