.truncatedContainer {
	display: inline-flex;
	align-items: center;
	max-width: 100%;
}

.textContent {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: clip;
	line-height: 1.2;
	display: block;
}

.pointerCursor {
	cursor: pointer;
	color: #1890ff;
	margin-left: 4px;
	font-weight: bold;
	display: inline-block;
}

.tooltipOverlay {
	max-width: 300px;
}

.anexoPointerCursor {
	cursor: pointer;
}
