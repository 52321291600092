:global {
	.ant-drawer-content-wrapper {
		height: 640px !important;
	}

	.ant-drawer {
		position: absolute !important;
		top: 14px !important;
		left: 0;
		height: 100%;
	}
}

.iconeFechar {
	cursor: pointer !important;
}

.notificationExit {
	padding-bottom: 10px;
	border-bottom: 1px solid #f0f0f0;
	transition: opacity 0.3s ease;

	&.notificationExitActive {
		opacity: 0;
	}
}

.descricaoNovidade {
	width: 100%;
	overflow-wrap: break-word;
	word-wrap: break-word;
	white-space: pre-wrap;
}
