.modalCancelarObrigacao {
	background: none !important;
	max-width: 400px !important;
	padding: 0px !important;
	min-width: 400px !important;
}

.dataCancelamento {
	max-width: 150px !important;
	margin-bottom: 16px !important;
}

.buttonsContainer {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	gap: 16px !important;
	margin-top: 20px !important;
}

input[disabled],
textarea[disabled] {
	opacity: 1; /* Mantém a visibilidade total */
	color: #000000 !important; /* Ajuste a cor do texto, se necessário */
	background-color: #ffffff !important; /* Opcional: define uma cor de fundo clara */
	cursor: not-allowed; /* Mantém o cursor indicando que o campo está desabilitado */
}
