.modalContainer {
	border-radius: 8px !important;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
	background-color: none !important;
	max-height: 90vh;
	overflow-y: auto;
	padding: 0px !important;
	max-width: 90vw !important;
}

.modalHeader {
	margin-bottom: 1rem;
	border-bottom: 1px solid #ccc;
	padding-bottom: 0.5rem;

	h2 {
		font-size: 1.5em;
		color: #333;
		margin: 0;
	}
}

.modalContent {
	display: grid;
	gap: 1rem;
}

.ant-modal .ant-modal-content {
	padding: 20px 0px !important;
}