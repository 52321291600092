.containerRelatorios {
	background-color: transparent !important;
	height: 100%;
}

.colRelatorio {
	background: #fff;
	padding: 14px 18px;
	border-radius: 8px;
}

.flexRelatorio {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 10px;
}

.botaoCustomizado {
	width: 40px;
	padding: 16px 4px !important;
	border-radius: 8px 0 0 8px !important;
	position: absolute;
	top: 200px;
	left: -42px;
	z-index: 1;
}

.flexColuna {
	display: flex;
	flex-direction: column;
}

.tipografiaCustomizada {
	writing-mode: vertical-rl;
	transform: rotate(180deg);
	font-size: 12px;
	margin-top: 0.5rem;
}

.botaoOcultarMenu {
	padding: 16px 4px !important;
	border-radius: 8px 0 0 8px !important;
	margin: 0px -8px 20px -20px;
}

.flexRow {
	display: flex;
}

.tipografiaOcultar {
	font-size: 12px;
	margin-right: 0.5rem;
}

.iconeOcultar {
	font-size: 16px;
	cursor: pointer;
}

.containerSelecaoFiltros {
	padding: 16px 0;
	overflow: auto;
	height: 560px;
	width: 90%;
}

.droppableContainer {
	display: flex;
	gap: 0.3rem;
	overflow-x: auto;
	white-space: nowrap;
	padding-bottom: 1rem;
	width: 100%;
	flex-direction: column;
}

.containerDragIndicador {
	width: 100%;
	padding: 6px;
	// border-radius: 20px;
}
