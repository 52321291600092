.containerAnexo {
  max-width: 1000px !important;
  width: 800px !important;
  padding: 20px !important;
  border-radius: 8px !important;
}

.modalAnexoContainer {
  max-width: 850px !important;
  width: 100% !important;

}

.containerAnexoHeader {
  display: flex !important;
  justify-content: space-between !important;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 20px !important;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  gap: 10px;
}

.uploadArea {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;

}

.typographyH6 {
  font-weight: bold;
  font-family: Inter, sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 14.52px !important;
  text-align: left !important;
  color: #000000 !important;
}

.typographyParagraph {
  font-family: Inter, 'sans-serif' !important;
  font-size: 12px !important;
  font-style: italic !important;
  font-weight: 400 !important;
  line-height: 14.52px !important;
  text-align: left !important;
  color: var(--Extended-Colors-Amarelo, #805600) !important;
  margin-top: 10px;
}


.anexosContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.uploadButton {
  display: flex;
  flex-direction: column !important;
  width: 100% !important;
  padding: 10px 0 !important;
}

.uploadContainer {
  display: flex;
  align-items: center;
}

.uploadIcon {
  margin-right: 8px;
}


.descricaoInputAnexo {
  width: 100% !important;
  padding: 0 20px;
  margin-bottom: 10px;

}