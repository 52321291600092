.barraDeProgressoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 8px;
    text-align: center;
}

.tempoEstimado {
    display: none;
    font-size: 14px; 
    color: #555; 
    margin-top: 8px; 
}
