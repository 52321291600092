.operacoesEmMassaContainer {
  padding: 20px;
  background-color: #ffff;

  h1 {
    font-size: 24px;
    margin-bottom: 0px;
  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.modal-content {
  padding: 20px;
}

.modal-content button {
  position: absolute;
  top: 10px;
  right: 10px;
}