.rotulo {
	color: white;
	padding: 4px;
	border-radius: 4px;
	min-width: 23px;
	height: 28px;
	text-align: center;
	font-size: 13px;

	&.todos {
		background-color: #186584;
	}

	&.vencidos {
		background-color: #ba1a1a;
	}

	&.aguardando {
		background-color: #fab35a;
	}
	&.noPrazo {
		background-color: var(--md-sys-color-verde-light);
	}
	&.concluidas {
		background-color: var(--md-sys-color-primary-light);
	}

	&.selecionados {
		background-color: #ffab00; 
	}
}

