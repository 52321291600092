body {
	margin: 0;
	overflow-x: hidden;
}

.ant-select-dropdown .ant-select-dropdown-hidden {
	z-index: 9999 !important;
}

.ant-select-dropdown {
	z-index: 9999 !important;
}

.campoObrigatorio {
	width: fit-content;
	&::after {
	  content: '*';
	  color: red;
	  margin-left: 4px;
	  font-weight: bold;
	  display: inline;
	}
  }
  