.modalFormEditContainer {
	width: 100% !important;
	border-radius: 8px !important;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
}

.textColor {
	color: #ccc;
}

.cursorPointer {
	cursor: pointer;
}

.pointer {
	cursor: pointer;
}

.disabledCursor {
	cursor: not-allowed;
}

.iconSize {
	font-size: 24px;
	margin-left: 4px;
}

.disabledColor {
	color: #ccc;
}

.defaultColor {
	color: inherit;
}
