.modalFormContainer {
	width: 100% !important;
	border-radius: 8px !important;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
}

.editIcon {
	font-size: 18px;
	color: #000000;
	cursor: pointer;
	margin-left: 8px;

	&:hover {
		color: #078574;
	}
}

.dataVencimentoContainer {
	display: flex;
	align-items: center;
	justify-content: start !important;

}

.dataVencimentoIcon {
	margin-left: 8px;
	cursor: pointer;
	transition: color 0.3s;

	&:hover {
		color: #1890ff;
	}
}


.counterContainer {
	display: flex;
	align-items: center;
	gap: 16px;
	margin-bottom: 16px;

}


.counterItemTodos {
	padding: 12px;
	background-color: #e6f7ff;
	border-radius: 8px;

}

.counterItemSelecionadas {
	padding: 8px;
	border-radius: 8px;
	position: relative;
	z-index: 1000;

}

.selectAllButton {
	position: relative;
	z-index: 1000;
}