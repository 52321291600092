.alertContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background-color: rgba(255, 221, 176, 0.38); 
    border: 1px solid #805600; 
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #8c6d1f;
    max-width: 400px;
    position: relative;
    margin-bottom: 1rem !important;
    margin-top: -1rem !important; 
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      font-size: 14px;
      color: #fff; 
      background-color: #ffab00; 
      border-radius: 50%;
    }
  
    .message {
      flex: 1;
      color: #805600;
    }
  
    .closeIcon {
      cursor: pointer;
      font-size: 16px;
      color: #805600;
    }
  }
  